import React from "react"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import withAuth from "../../components/withAuthHoc"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import { Button, Col, Icon, Row, Tabs, message } from "antd"
import { Template } from "../../layouts/base"
import { BsArrowBarLeft } from "react-icons/bs"
import {
  createModel,
  datasetIsCreating,
  datasetIsFailed,
  deleteDataset,
  deleteModel,
  getDatasets,
  getModels,
} from "../../services/dataset"
import ComponentList from "../../components/dataset/componentList"
import ModelList from "../../components/dataset/modelList"
import ModelForm from "../../components/dataset/modelForm"
import DatasetDeleteModal from "../../components/dataset/datasetDeleteModal"
import ModelDeleteModal from "../../components/dataset/modelDeleteModal"
import moment from "moment-timezone"
import { checkPermissions } from "../../services/auth/permissions/permissions"

const { TabPane } = Tabs
const ONE_MIN_IN_MS = 60000

class DatasetDetail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dataset: {},
      datasetIdUrlParam:
        props.location && props.location.pathname.split("/").pop(),
      loading: true,
      models: null,
      modelNames: null,
      showModelForm: false,
      deleteDatasetModalVisible: false,
      deleteModelModalVisible: false,
      modelToDelete: null,
      lastUpdate: null,
    }
    this.interval = null
    this._formatMessage = (id, parametrized) =>
      this.props.intl.formatMessage({ id }, { ...parametrized })
  }

  componentDidMount() {
    this._loadDataset()
    this._loadModels()
  }

  componentWillUnmount() {
    this._clearInterval()
  }

  _loadDataset() {
    this._clearInterval()
    if (this.state.datasetIdUrlParam) {
      this._loadModelsPeriodically()
      getDatasets({}, this.state.datasetIdUrlParam).then(dataset =>
        this.setState({ dataset, loading: false })
      )
    } else {
      message.error(this._formatMessage("page-dataset-load-datasets-ko"))
      console.error('[ERROR]: No "id" retrieved from URL in order to query it.')
    }
  }

  async _loadModels() {
    checkPermissions(["model:read"]) &&
      (await getModels()
        .then(models =>
          this.setState({
            modelNames: models.map(m => m.model_name),
            models: models.filter(
              model => model.dataset_name === this.state.datasetIdUrlParam
            ),
          })
        )
        .catch(error => {
          error.json().then(error => {
            message.error(
              this._formatMessage("page-dataset-load-models-ko", {
                error: error.message,
              })
            )
            console.error(
              '[ERROR]: No "id" retrieved from URL in order to query it.'
            )
          })
        }))
  }

  async _onModelSubmit(data) {
    await createModel(data)
      .then(response => {
        message.info(
          this._formatMessage("page-dataset-create-model-ok", {
            modelName: response.model_name,
          })
        )
        let models = this.state.models
        models.push(response)
        this.setState({ models, showModelForm: false })
      })
      .catch(err => {
        message.error(
          `${this._formatMessage(
            "page-dataset-create-model-ko"
          )} Status Code: ${err}`
        )
      })
  }

  _updateLastUpdateDate() {
    this.setState({ lastUpdate: moment().format("HH:mm:ss") })
  }

  _loadModelsPeriodically() {
    this._clearInterval()
    this._updateLastUpdateDate()
    this.interval = setInterval(
      () => this._loadModelsInBackground(),
      ONE_MIN_IN_MS
    )
  }

  _loadModelsInBackground() {
    this._loadDataset()
    this._loadModels()
  }

  _clearInterval() {
    if (this.interval !== null) {
      clearInterval(this.interval)
    }
  }

  _onDeleteDatasetClick() {
    this.setState({ deleteDatasetModalVisible: true })
  }

  _onDeleteDatasetCancel() {
    this.setState({ deleteDatasetModalVisible: false })
  }

  async _onDeleteDatasetConfirm() {
    this.setState({ deleteDatasetModalVisible: false })
    await deleteDataset(this.state.dataset.dataset_name)
      .then(response => {
        if (response.message === "Dataset deleted successfully!.") {
          message.info(this._formatMessage("page-dataset-delete-dataset-ok"))
          navigate("/dataset")
        } else {
          message.error(this._formatMessage("page-dataset-delete-dataset-ko"))
        }
      })
      .catch(() =>
        message.error(this._formatMessage("page-dataset-delete-dataset-ko"))
      )
  }

  _onDeleteModelClick(modelToDelete) {
    this.setState({ deleteModelModalVisible: true, modelToDelete })
  }

  _onDeleteModelCancel() {
    this.setState({ deleteModelModalVisible: false, modelToDelete: null })
  }

  async _onDeleteModelConfirm() {
    await deleteModel(this.state.modelToDelete)
      .then(response => {
        if (response.message === "Model deleted successfully!.") {
          message.info(this._formatMessage("page-dataset-delete-model-ok"))
          this.setState({
            models: this.state.models.filter(
              model => model.model_name !== this.state.modelToDelete
            ),
          })
        } else {
          message.error(this._formatMessage("page-dataset-delete-model-ko"))
        }
        this.setState({ deleteModelModalVisible: false, modelToDelete: null })
      })
      .catch(() =>
        message.error(this._formatMessage("page-dataset-delete-model-ko"))
      )
  }

  _renderDatasetStatus(status) {
    if (!status) {
      return
    }
    const options = {
      CREATED: {
        message: "dataset-status-created",
        image: <Icon type="clock-circle"></Icon>,
      },
      INGESTION_IN_PROGRESS: {
        message: "dataset-status-ingest-in-progress",
        image: <Icon type="clock-circle"></Icon>,
      },
      IN_PROGRESS: {
        message: "dataset-status-in-progress",
        image: <Icon type="clock-circle" />,
      },
      ACTIVE: {
        message: "dataset-status-active",
        image: <div className="ingest-status ingest-status--ok" />,
      },
      FAILED: {
        message: "dataset-status-failed",
        image: <div className="ingest-status ingest-status--ko" />,
      },
      DEFAULT: {
        message: this.state.dataset.status,
        image: <div />,
      },
    }
    return (
      <div
        style={{ display: "flex", alignItems: "center", marginRight: "1em" }}
      >
        {options[options[status] ? status : "DEFAULT"].image}
        &nbsp; &nbsp; &nbsp;
        {this.props.intl.formatMessage({
          id: options[options[status] ? status : "DEFAULT"].message,
        })}
      </div>
    )
  }

  render() {
    const _thatIntl = this.props.intl
    return (
      <Template selected={["dataset", "dataset-index"]}>
        <Row>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <CustomBreadcrumb
              style={{ flexGrow: "1" }}
              crumbs={[
                {
                  label: _thatIntl.formatMessage({ id: "menu-datasets" }),
                  link: "/dataset",
                },
                _thatIntl.formatMessage({ id: "details" }),
              ]}
            />
            {this.state.lastUpdate !== null ? (
              <div>
                {this.props.intl.formatMessage({ id: "last-update" })}:{" "}
                {this.state.lastUpdate}
              </div>
            ) : null}
          </div>
        </Row>
        <Row>
          <Col className="knolar-intro" span={12}>
            <Icon
              component={BsArrowBarLeft}
              onClick={() => navigate("/dataset")}
            />
            {this.state.datasetIdUrlParam}
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {checkPermissions(["dataset:delete"]) ? (
              <Button
                type="primary"
                onClick={() => this._onDeleteDatasetClick()}
                className="knolar-button dataset-button"
              >
                {_thatIntl.formatMessage({ id: "delete-dataset" })}
              </Button>
            ) : null}
          </Col>
        </Row>
        <Row className="content dataset-resume">
          <Col span={12}>
            <div className="resume-item">
              <div className="model-item-title">
                {this._formatMessage("dataset-table-status")}
              </div>
              <div className="resume-content">
                {this._renderDatasetStatus(this.state.dataset.status)}
              </div>
              {this.state.dataset.status === "FAILED" ? (
                <div>{this.state.dataset.error}</div>
              ) : null}
            </div>
          </Col>
          <Col span={12}>
            <div className="resume-item">
              <div className="model-item-title">
                {this._formatMessage("model-detail-creating-date-title")}
              </div>
              <div className="resume-content">
                {this.state.dataset.created_at
                  ? moment(this.state.dataset.created_at).format("YYYY-MM-DD")
                  : "--"}
              </div>
            </div>
            {this.state.dataset.form ? (
              <div style={{ display: "flex" }}>
                <div className="resume-item">
                  <div className="model-item-title">
                    {this._formatMessage("dataset-detail-range")}
                  </div>
                  <div className="resume-content">
                    {moment(this.state.dataset.form.t_init).format(
                      "YYYY-MM-DD"
                    )}
                    &nbsp;-&nbsp;
                    {moment(this.state.dataset.form.t_end).format("YYYY-MM-DD")}
                  </div>
                </div>
              </div>
            ) : null}
          </Col>
        </Row>
        <Tabs className="dataset-tabs" defaultActiveKey="1">
          <TabPane
            tab={_thatIntl.formatMessage({
              id: "new-dataset-components-title",
            })}
            key="1"
            forceRender={true}
          >
            {this.state.loading ? null : (
              <ComponentList
                components={this.state.dataset.form.components_field_map}
                isEditing={false}
              />
            )}
          </TabPane>
          <TabPane
            tab={_thatIntl.formatMessage({ id: "dataset-table-models" })}
            key="2"
            forceRender={true}
            disabled={
              datasetIsCreating(this.state.dataset) ||
              datasetIsFailed(this.state.dataset) ||
              !checkPermissions(["model:read"])
            }
          >
            <ModelList
              models={this.state.models}
              onDeleteModelClick={evt => this._onDeleteModelClick(evt)}
            />
            {this.state.showModelForm ? (
              <Row>
                <div style={{ maxWidth: "450px" }}>
                  <ModelForm
                    modelNames={this.state.modelNames}
                    onSubmit={this._onModelSubmit.bind(this)}
                    datasetName={this.state.datasetIdUrlParam}
                    datasetInit={this.state.dataset.form.t_init}
                    datasetEnd={this.state.dataset.form.t_end}
                  />
                </div>
              </Row>
            ) : checkPermissions(["model:write"]) ? (
              <Button
                type="primary"
                onClick={() => this.setState({ showModelForm: true })}
                className="knolar-button dataset-button"
                loading={Object.keys(this.state.dataset).length === 0}
              >
                {_thatIntl.formatMessage({ id: "create-model" })}
              </Button>
            ) : null}
          </TabPane>
        </Tabs>
        <DatasetDeleteModal
          visible={this.state.deleteDatasetModalVisible}
          onOk={() => this._onDeleteDatasetConfirm()}
          onCancel={() => this._onDeleteDatasetCancel()}
        />
        <ModelDeleteModal
          visible={this.state.deleteModelModalVisible}
          onOk={() => this._onDeleteModelConfirm()}
          onCancel={() => this._onDeleteModelCancel()}
        />
      </Template>
    )
  }
}

export default injectIntl(withAuth(DatasetDetail))
